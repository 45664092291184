import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import EditList from './edit/EditList.js';
ReactDOM.render(
  <React.StrictMode>
    <App />
     {/* <EditList /> */}
  </React.StrictMode>,
  document.getElementById('root')
);
