import React from 'react';
class MenuHeader extends React.Component{
      render(){
            return( <div className="header">
            <h1>MENY</h1> 
            <img
                  src="https://d33wubrfki0l68.cloudfront.net/5bfd78f424312a6b4e3a35f22047ba2cffa934e2/af85c/img/cyb-stor.svg" 
                  alt="logo" 
                  id="logo"/>
            </div>
            );
      }
}
export default MenuHeader;
